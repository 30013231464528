import React, { useState, useEffect } from 'react';
import { CSVImport } from '@src/component/rule-engine/import/CSVImportFIle'
import { importCsv, uploadAddOnCsv } from '@src/store/slices/common';
import { toast } from 'react-toastify';
import secureStorage from "@src/config/encrypt";
import FullPageLoader from '@src/common/FullPageLoader';
import * as HELPER from "@src/config/helper";
import { APP_AGENCY, ROLE_FEATURES } from "@src/config/constant";
import ReactSelect from '@common/ReactSelect';
import { getMasterConfig } from '../../../store/slices/common';
import { useDispatch, useSelector } from 'react-redux'
// import { bulkUploadLeadLogs } from '@store/slices/insurance';
// import { getBulkUploadLeadLogs, downloadBulkUploadLeadLogs } from '../../../store/slices/insurance';
import dateFormat from "dateformat";
import { getBulkUploadLeadLogs, downloadBulkUploadLeadLogs, getSampleFile } from '@src/store/slices/insurance';


interface MRPBCAMFModalProps {
    setUpdateModal: any;
    type: any;
    closeBulkUploadModal: any;
}


const BulkImportModal: React.FC<MRPBCAMFModalProps> = ({ setUpdateModal, type, closeBulkUploadModal }) => {
    const [csvValue, setCsvValue] = useState<any>(null);
    let [loading, setLoading] = useState<boolean>(false)
    let [emptyErr, setEmptyErr] = useState<boolean>(false)

    const [csvReset, setCsvReset] = useState(0);

    const [BlankFlage, setBlankFlage] = useState(0);
    const [urlLink, setUrlLink] = useState('');

    const dispatch: any = useDispatch()
    const commonState = useSelector((state: any) => state.common)
    let [methodList, setMethodList] = useState<[]>([])
    const [selectedFilters, setSelectedFilters] = useState<any>({application_type: APP_AGENCY});
    const [errField, setErrField] = useState<any>({
        appType: ""
    });

    const [bulkLog, setBulkLog] = useState([]);

    const insuranceState = useSelector((state: any) => state.insurance)

    const validForm = () => {
        let formIsValid = true;
        // App Type required only for base issue rate
        // if (type === 'Base Issue Rate') {
        //     if (Object.keys(selectedFilters).length === 0) {

        //         formIsValid = false;
        //         setErrField((prevState: any) => ({
        //             ...prevState,
        //             appType: "Application Type is required",
        //         }));
        //     }
        // }

        if (!csvValue) {

            formIsValid = false;
            setEmptyErr(true);
        }
        return formIsValid;
    };


    const handleImport = () => {
        if (validForm()) {
            let result = secureStorage.getItem("authUserInfo");
            let userId = result && result.user_data && result.user_data.id

            setLoading(true);

            let formData = new FormData();
            formData.append("type", type);
            formData.append("images", csvValue);
            formData.append("user_id", userId);
            formData.append("application_type", selectedFilters.application_type);

            
            uploadAddOnCsv(formData).then((res: any) => {
                setLoading(false)
                closeBulkUploadModal()
                if (res && res.status === 200) {
                    toast.success(res.message);
                    setUpdateModal(false);
                    setCsvValue('');
                    setBlankFlage(BlankFlage + 1);

                    // if (type === 'Base Issue Rate') {
                    //     setSelectedFilters({});
                    // }

                    setLoading(true)
                    getBulkUploadLeadLogs().then((data: any) => {
                        setBulkLog(data?.data)
                        setLoading(false)
                    });

                } else {
                    setLoading(false);
                    setCsvValue('');
                    setBlankFlage(BlankFlage + 1);
                }

            }).catch(err => setLoading(false))


            setCsvReset(csvReset + 1);
        }
    };

    useEffect(() => {

        if(!insuranceState?.sampleCsv?.length) {
            dispatch(getSampleFile()).then((res: any) => {
                if (res && res.status === 200) {
                    
                }
            }).catch((err: Error) => {
            })
        }

        setLoading(true)
        getBulkUploadLeadLogs().then((data: any) => {
            setBulkLog(data?.data)
            setLoading(false)

        });

        // eslint-disable-next-line
    }, [type]);


    useEffect(() => {
        if (commonState && commonState.common && Object.keys(commonState.common).length) {
            let data = commonState.common
            let { apptype } = data
            setMethodList(apptype)

        }
        else {
            //api call to fetch static dropdowns
            dispatch(getMasterConfig()).then((res: any) => {
                if (res && res.status === 200) {
                    let data: any = res.data || {}
                    let { apptype } = data
                    setMethodList(apptype)

                }
            }).catch((err: Error) => {
            })
        }
    }, [dispatch, commonState])


    // Handle changes in dropdown selections
    const handleChange = (selectedOption: any, key: string) => {
        setSelectedFilters({ [key]: selectedOption['value'] })
        errField['appType'] = '';
    }

    const downloadBulkUploadLeadLog = (logId: number) => {

        downloadBulkUploadLeadLogs({logId}).then((data: any) => {
            // setBulkLog(data?.data)
            if(data.status===200){

                let link = document.createElement("a");
                let urlName = data.data?.split('?')?.[0];
                urlName = urlName?.split('/');
                
                link.download = urlName?.[urlName.length-1];
                
                link.href = data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                // delete link;
            }
        });
    }

    useEffect(()=>{
        const match = insuranceState.sampleCsv.find((insurance: any) => insurance.slug === type);
            
        if (match) {
            setUrlLink(match.path);
        }
    }, [insuranceState.sampleCsv])

    return (

        <>
            <div className="bulk-upload-sec">
                <div className="calculator-form csv-table">
                    <FullPageLoader show={loading} />
                    <div className="row">
                            <div className="col-md-12">
                                <ReactSelect
                                    options={methodList?.filter((option: any) => option.value === APP_AGENCY) || []}
                                    value={methodList.find((option: any) => option.value === APP_AGENCY) || null}
                                    handleChange={(e: any, fieldKey: any) => handleChange(e, fieldKey)}
                                    placeholder="Application Type*"
                                    fieldKey="application_type"
                                    errorField={errField['appType'] || ""}
                                />
                            </div>
                            
                        <div className="col-md-12">
                            <h3>Upload File</h3>
                                <CSVImport setCsvValue={setCsvValue} csvReset={csvReset} emptyErr={emptyErr} BlankFlage={BlankFlage} setError={setEmptyErr} fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/excel', 'application/x-excel', 'application/x-msexcel']} />
                            <div>
                            </div>
                            <div className="download-txt">
                                Download input format < a href={`${urlLink}`} download>Click Here</a>
                            </div>
                        </div>
                        {
                            HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['bulk_upload_application'], accessType: 'edit' }) &&
                            <div className="col-md-12">
                                <button className="btn-primary" onClick={handleImport}>Submit</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="bulk-history-sec">
                <h3>Upload History</h3>
                <div className="bulk-history-table">
                    <div className="data-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date &amp; Time</th>
                                    <th>Who</th>
                                    <th>Records</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bulkLog?.length>0 ?
                                    bulkLog?.map(( log:any, key:number)=>
                                    <tr key={key}>
                                        <td> {log.created_at }</td>
                                        <td><span className="truncate">{log.name}</span></td>
                                        <td>
                                            <ul className="record-list-data">
                                                <li title='Success Count'>{log.successCount}</li>
                                                <li><p title='Error Count'>{log.errorCount}</p></li>
                                                <li title='Total Count'>{log.total_count}</li>
                                            </ul>
                                        </td>
                                        <td><span className="truncate">{((+log.successCount + +log.errorCount) === +log.total_count)? "Completed": "In Progress"}</span></td>
                                        <td>
                                            <ul className="action-btn">
                                                <li onClick={()=>downloadBulkUploadLeadLog(log.id)}><i className="ic-save_alt"></i></li>
                                            </ul>
                                        </td>
                                    </tr>)
                                    :
                                    <tr><td className='t-center' colSpan={5}>No Logs Found</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>

    )
}
export default BulkImportModal;