import ReactSelect from '@common/ReactSelect';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { ADD_ONS_TYPE, APP_TYPE_RENEWAL } from 'config/constant';
import * as HELPER from "../../../config/helper";
import { getAddonList } from '../create/FunctionList'
import { toast } from 'react-toastify';


const AddonForm = (props: any) => {
    const commonState = useSelector((state: any) => state.common);
    const [value, setValue] = useState(3);
    const [addonCount, setAddonCount] = useState<number>(0);
    // Validate input field of allow two decimal value 
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
    const [popupAddons, setPopupAddons] = useState<any>([]);

    useEffect(() => {
        if (props && props?.addonCount) {
            setAddonCount(props?.addonCount);
        }
        // eslint-disable-next-line
    }, [props?.addonCount]);


    const onChangeCheckbox = (addonItem: any, event: any) => {

        if (addonItem.is_required && addonItem.is_selected) {
            return false;
        } else {
            let count = addonCount;
            count = (event.target.checked) ? count + 1 : count - 1;
            setAddonCount(count)
        }
        if (addonItem.is_required) {
            addonItem.is_selected = true
        } else {
            addonItem['is_selected'] = !addonItem.is_selected
        }
        setValue(value + 1)
    }

    const onValueSelect = (addonItem: any, event: any) => {
        addonItem['coverage_amt'] = event.value
        addonItem['coverage_amount'] = event.value
        addonItem['premium_amount'] = (event.premium_amount && isRuleEngine()) ? event.premium_amount : addonItem.premium_amount

        setValue(value + 1)
    }

    const onChangePremiumAmount = (addonItem: any, text: any) => {
        // validate two decimal digit value
        if (regex.test(text) || text === '') {
            let isComprensive = props.insuranceState?.insurance_type_id === 1 ? true : false;
            if (!isComprensive && isRuleEngine())
                addonItem.coverage_amt = text ? text : ''
            else
                addonItem.premium_amount = text ? text : ''

            setValue(value + 1)
        }
    }


    const showHideAddons = async (action: any) => {
        let { checkValue, message }: any = await isValidAddon();
        if (checkValue) {
            props.setAddon(popupAddons);
            props.showHideAddons(action);
        } else {
            for (let msg of message) {
                toast.error(msg);
            }
        }
    }

    const isValidAddon = async () => {
        let flag = true;
        let message: any = [];
        // let addonList: any = getAddonList([], props.addonList);
        let addonList: any = getAddonList([], popupAddons);
        if (!addonList?.length) {
            //SKIP IF NO ADDON SELECTED
            // message.push("Please select addons");
            // flag = false
        } else {
            for (let data of addonList) {
                if (data.id === 1) {
                    if (!data["coverage_amount"]) {
                        message.push("Please select coverge amount for Bodily Injured");
                        flag = false
                    }
                    if (!data["premium_amount"]) {
                        message.push("Please Enter Premium amount for Bodily Injured");
                        flag = false
                    }
                } else if (data.id === 2) {
                    if (!data["coverage_amount"]) {
                        message.push("Please select coverge amount for Property Damage");
                        flag = false
                    }
                    if (!data["premium_amount"]) {
                        message.push("Please Enter Premium amount for Property Damage");
                        flag = false
                    }
                } else if (data.id === 3) {
                    if (!data["coverage_amount"]) {
                        message.push("Please select coverge amount for Personal Accident");
                        flag = false
                    } else if (!data["seat_capacity"]) {
                        message.push("Please select seat capacity for Personal Accident");
                        flag = false
                    }

                    // if(applicationType===2 && !data["premium_amount"]){
                    //     message.push("Please Enter Premium amount for Personal Accident");
                    //     flag = false
                    // }

                    if(!data["premium_amount"] && ['oona', 'milestone'].includes(props?.insuranceState?.partner_slug)){
                        let premiumAmtValidation = true;
                        if(data['milestone_coverage_amount_limit'] || data['oona_coverage_amount_limit']){
                            if(data['milestone_coverage_amount_limit'] === data['coverage_amount'] || data['oona_coverage_amount_limit'] === data['coverage_amount']){
                                premiumAmtValidation = false;
                            }
                        }
                        if(premiumAmtValidation || props?.insuranceState?.rule_engine_id === 2){

                            message.push("Please Enter Premium amount for Personal Accident");
                            flag = false
                        }
                    }

                }
            }

        }

        return new Promise((resolve) => resolve({ checkValue: flag, message }));
    }

    const calculateTotalPremiumValue = () => {
        let total = 0
        let filterList = props.addonList?.filter((item: any) => item?.is_selected)
        if (filterList?.length) {
            filterList?.forEach((item: any) => {
                let premiumAmount = getPremiumAmount(item)
                if (ADD_ONS_TYPE.ACTS_OF_NATURE !== item.type)
                    if (premiumAmount)
                        total += parseFloat(premiumAmount ?? '0')
            })
        }
        return HELPER.addCurrencyFormatting(total)
    }

    const getPremiumAmount = (item: any) => {
        let isComprensive = props.insuranceState?.insurance_type_id === 1 ? true : false;
        if (isComprensive && isRuleEngine()) {
            if (ADD_ONS_TYPE.PERSONAL_ACCIDENT === item.type) {
                return item.premium_amount || ''
            }
            let filterItem = item?.coverage_list?.filter((option: any) => option.value === item.coverage_amt)?.[0]
            if (filterItem)
                return filterItem?.premium_amount || ''
            else
                return 0
        } else if (!isComprensive && !isRuleEngine()) {
            return item.premium_amount ? item.premium_amount : props?.insuranceState?.bi_amount
        }
        else {
            return item.premium_amount ? item.premium_amount : ''
        }
    }
    const getPremiumTitle = (item: any) => {
        let isComprensive = props.insuranceState?.insurance_type_id === 1 ? true : false;
        if (ADD_ONS_TYPE.PERSONAL_ACCIDENT === item.type && isComprensive && isRuleEngine()) {
            return ''
        }
        else if (ADD_ONS_TYPE.ACTS_OF_NATURE === item.type || ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL === item.type || ADD_ONS_TYPE.TOWING_ROWDSIDE === item.type) {
            return ''
        } else if (isComprensive && isRuleEngine()) {
            return `Premium Amount : ${HELPER.addCurrencyFormatting(getPremiumAmount(item))}`
        }
        return ''
    }
    const isRuleEngine = () => {
        if (props.insuranceState?.rule_engine_id === 1) return true
        else return false;
    }    
    // const isShowPremiumInputBox = (addonItem: any) => {
    //     let isComprensive = props.insuranceState?.insurance_type_id === 1 ? true : false;
    //     if (((!isComprensive && !isRuleEngine()) || (!isRuleEngine() && isComprensive)) && ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL !== addonItem.type && ADD_ONS_TYPE.TOWING_ROWDSIDE !== addonItem.type) {
    //         return true
    //     }
    //     else {
    //         if (addonItem.is_editable && !isRuleEngine()) { //ONLY IN CASE OF TRA WITHOUT RULE ENGINE
    //             if (ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL === addonItem.type && props?.insuranceState?.partner_slug !== 'oona') return false
    //             return true
    //         } else {
    //             return false
    //         }
    //     }
    // }

    const isShowPremiumInputBox = (addonItem: any) => {
        let isComprensive = props.insuranceState?.insurance_type_id === 1 ? true : false;

        if (((!isComprensive && !isRuleEngine()) || (!isRuleEngine() && isComprensive)) && ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL !== addonItem.type && ADD_ONS_TYPE.TOWING_ROWDSIDE !== addonItem.type){
            return true
        }
        else{
            if(addonItem.is_editable && !isRuleEngine()){ //ONLY IN CASE OF TRA WITHOUT RULE ENGINE
                if (ADD_ONS_TYPE.RIOTS_STRIKES_CIVIL === addonItem.type && props?.insuranceState?.partner_slug !== 'oona') return false

                return true
            }else{
                //FOR OONA & MERCENTILE IF COVERAGE AMT IS MATCHED FROM API THEN SHOW INPUT BOX
                if(addonItem.type === ADD_ONS_TYPE.PERSONAL_ACCIDENT && ['oona', 'milestone'].includes(props?.insuranceState?.partner_slug)){
                    if(addonItem.coverage_amt === addonItem.oona_coverage_amount_limit || addonItem.coverage_amt === addonItem.milestone_coverage_amount_limit){
                        addonItem.premium_amount = ''
                        return false
                    }else{
                        return (addonItem.coverage_amt) ? true : false //MANUAL PREMIUM INPUT ALLOW 
                    }

                }else{
                    return false
                } 
            }
        }
    }

    const saveExtraSeatingValue = (item: any, event: any) => {
        item.extra_seat_value = event.value
        setValue(value + 2)

    }

    const saveSeatingValue = (item: any, event: any) => {
        item.seat_capacity = event.value
        setValue(value + 3)
    };

    useEffect(()=>{
        if(props.addonList?.length){

            let addonsData = JSON.parse(JSON.stringify(props.addonList))
            setPopupAddons([...addonsData]);
        }
    }, [props.addonList])

    return (
        <React.Fragment>
            <div className="modal-header">
                <h2>Add Ons Selection</h2>
            </div>
            <div className="modal-body" key={'addon-body'}>
                <div className="form-group-bx">
                    {
                        popupAddons.length ?

                            popupAddons.map((item: any) => {
                                return (
                                    <React.Fragment>
                                        <div className="row AddonSection m-md-t quote-detail-form">
                                            <fieldset className="form-filed col-md-12 m-md">
                                                <div className="material">
                                                    <div className="custom-control custom-checkbox">
                                                        {
                                                            (props?.insuranceState?.application_type_id === APP_TYPE_RENEWAL && item.type === 'pa' && props?.insuranceState?.parent_pa_exist) //disable PA if parent lead has PA selected 
                                                                ?
                                                                <>
                                                                    <input checked={item.is_selected} id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type"/>
                                                                    <label className="custom-control-label">{item.name}</label>
                                                                </>
                                                                :
                                                                <>

                                                                    <input checked={item.is_selected} id="Individual" type="checkbox" className="custom-control-input " name="Buyer Type" onChange={(event) => {
                                                                        onChangeCheckbox(item, event)
                                                                    }} />
                                                                    <label className="custom-control-label">{item.name}</label>
                                                                </>

                                                        }
                                                    </div>
                                                </div>
                                            </fieldset>

                                            {
                                                item.coverage_list.length ?

                                                    <ReactSelect
                                                        options={item.coverage_list || []} value={item.coverage_amt ? { label: item.coverage_amt, value: item.coverage_amt, premium_amount: '' } : null}
                                                        handleChange={(e: any) => onValueSelect(item, e)} placeholder="Choose Coverage Amount"
                                                        isDisabled={(props.insuranceType === 2) ? true : false}
                                                        fieldKey=""
                                                        
                                                    />
                                                    : null

                                            }
                                            {
                                                item.type === 'pa' &&
                                                <>
                                                    <ReactSelect
                                                        options={commonState.common.seat_capacity}
                                                        value={item.seat_capacity ? { label: item.seat_capacity, value: item.seat_capacity } : null}
                                                        handleChange={(e: any) => saveSeatingValue(item, e)} placeholder="Seating Capacity"
                                                        fieldKey=""
                                                        isDisabled={(props?.insuranceState?.application_type_id === APP_TYPE_RENEWAL && props?.insuranceState?.parent_pa_exist) ? true : false} //disable PA if parent lead has PA selected
                                                    />
                                                    <ReactSelect
                                                        options={commonState.common.seat_capacity}
                                                        value={item?.extra_seat_value ? { label: item.extra_seat_value, value: item.extra_seat_value } : null}

                                                        handleChange={(e: any) => saveExtraSeatingValue(item, e)}

                                                        placeholder="Extra Seat Added"
                                                        fieldKey=""
                                                        // isDisabled={(props?.insuranceState?.application_type_id === APP_TYPE_RENEWAL && props?.insuranceState?.parent_extra_seat) ? true : false} //disable PA if parent lead has PA selected
                                                    />
                                                </>

                                            }
                                            {
                                                getPremiumTitle(item)?.length > 0 &&
                                                <div className="material col-md-7 text-right premium-txt">
                                                    <span>{getPremiumTitle(item)}</span>
                                                </div>
                                            }
                                            {
                                                isShowPremiumInputBox(item) &&
                                                <fieldset className="form-filed col-md-6">
                                                    <div className="material">
                                                        <input id="" type="number" placeholder=" " name="RoleName" className="form-input" value={getPremiumAmount(item)} onChange={(event) => {
                                                            onChangePremiumAmount(item, event.target.value);
                                                        }} />
                                                        <label data-label="Premium Amount" className="form-label"></label>
                                                    </div>
                                                </fieldset>
                                            }

                                        </div>
                                    </React.Fragment>
                                )
                            })

                            : null
                    }

                </div>
            </div>
            <div className="modal-footer ">
                <div className="material addonwrapper p-md-b">
                    <div className="">
                        <p>{addonCount} Add Ons Selected</p> <p className='black'>Php       <label>{calculateTotalPremiumValue()}</label> </p></div>
                    <button className="btn-primary" onClick={showHideAddons.bind(this, false)} >Apply</button>
                </div>

            </div>
        </React.Fragment>
    )
}

export default AddonForm